<template>
  <section class="">
    <div class="reg-wrapper" v-loading="loading">
      <div class="reg-body">
        <h2>Recharge password</h2>
        <el-form
          class="forgot-form"
          ref="forgotForm"
          :model="forgotForm"
          :rules="forgotRules"
          :inline="true"
          label-width="0">
          <el-form-item class="reg-full" prop="email">
            <el-input type="email" v-model="forgotForm.email" maxlength="30" placeholder="Email">
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="password">
            <el-input type="password" v-model="forgotForm.password" maxlength="20" placeholder="Password" show-password>
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="password2">
            <el-input type="password" v-model="forgotForm.password2" maxlength="20" placeholder="Password Confirmation" show-password>
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="imgCode">
            <el-input class="img-code-input" type="text" v-model="forgotForm.imgCode" maxlength="6" placeholder="Image Code" @change="changeImgCode">
              <div slot="append" class="img-email-code">
                <!-- <a class="img-code" href="javascript:;" v-loading="forgotForm.imgLoading" element-loading-spinner="el-icon-loading" :style="forgotForm.imgSrc" @click="getImage"></a> -->
                <span class="img-code-box" v-loading="forgotForm.imgLoading" element-loading-spinner="el-icon-loading">
                  <img class="img-code" :src="forgotForm.imgSrc" @click="getImage" />
                </span>
                <el-button class="email-code" type="primary" v-loading="forgotForm.codeLoading" element-loading-spinner="el-icon-loading" @click="handleSendEmail">Send Email</el-button>
              </div>
              
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="code">
            <el-input type="text" v-model="forgotForm.code" maxlength="6" placeholder="Email Code">
              
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full login-submit">
            <el-button type="primary" @click="handleForgot">Confirm</el-button>
          </el-form-item>
        </el-form>
        <dl class="login-forgot">
          <dd>Remember the password? <el-link type="primary" @click="gotoLogin">
Sign in instead</el-link></dd>
        </dl>
      </div>
    </div>
  </section>
</template>

<script>
import md5 from 'js-md5'
import VpnHeader from '@/components/layout/header'
import { generateRandomString } from '@/utils/utils'
import { getKaptcha, sendEmail, setPassword } from '@/apis/common'
// const regex = /^.+@gmail\.com$/;
const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default {
  components: { VpnHeader },
  props: {},
  data () {
    var validateEmail = (rule, value, callback) => {
      // console.log(value)
      if (!regex.test(value)) {
        callback(new Error('Must use email!'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.forgotForm.password) {
        callback(new Error('The Confirm Password field confirmation does not match!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      forgotForm: {
        uuid: '',
        email: '',
        password: '',
        password2: '',
        imgLoading: false,
        imgSrc: require('@/images/common/img-code.png'),
        imgCode: '',
        codeLoading: false,
        code: ''
      },
      forgotRules: {
        email: [
          { required: true, message: 'Email', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Password', trigger: 'blur' },
          { min: 6, max: 20, message: 'Password length between 6-20 digits', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: 'Password Confirmation', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
        imgCode: [
          { required: true, message: 'Image Code', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Email Code', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    init() {
      var uuid = generateRandomString(16)
      // console.log(uuid)
      this.forgotForm.uuid = uuid
      this.getImage()
    },
    getImage() {
      this.forgotForm.imgLoading = true
      this.forgotForm.imgSrc = require('@/images/common/img-code.png')
      setTimeout(() => {
        this.forgotForm.imgSrc = '/api/email/kaptcha?token=' + this.forgotForm.uuid
        // this.forgotForm.imgSrc = 'background: url(/api/email/kaptcha?token=' + this.forgotForm.uuid + ') no-repeat 50% 50%; background-size: 100% 100%;'
        this.forgotForm.imgLoading = false
      }, 1200)
    },
    changeImgCode(val) {
      // console.log(val)
    },
    handleSendEmail() {
      if (this.forgotForm.email == '') {
        this.$message.warning('Please enter the email!')
      } else {
        if (regex.test(this.forgotForm.email)) {
          if (this.forgotForm.imgCode) {
            this.forgotForm.codeLoading = true
            sendEmail({
              token: this.forgotForm.uuid,
              email: this.forgotForm.email,
              code: this.forgotForm.imgCode
            }, (res) => {
              // console.log(res)
              setTimeout(() => {
                this.$message.success('Email sent successfully')
                this.forgotForm.codeLoading = false
              }, 1200)
            }, (err) => {
              // console.log(err)
              setTimeout(() => {
                this.forgotForm.codeLoading = false
              }, 1200)
            })
          } else {
            this.$message.warning('Please enter the image code!')
          }
        } else {
          this.$message.warning('Must use email!')
        }
      }
    },
    handleForgot() {
      this.$refs['forgotForm'].validate(valid => {
        if (valid) {
          this.loading = true
          setPassword({
            token: this.forgotForm.uuid,
            email: this.forgotForm.email,
            password: md5(this.forgotForm.password),
            retryPassword: md5(this.forgotForm.password2),
            code: this.forgotForm.code
          }, (res) => {
            // console.log(res)
            this.loading = false
            this.$confirm('Password recharge successful, log in?', 'Tips', {
              type: 'success',
              showClose: false,
              closeOnClickModal: false
            }).then(() => {
              this.$router.push('/login')
            }).catch(() => {
            });
          }, (err) => {
            // console.log(err)
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    gotoLogin() {
      this.$router.push('/login')
    }
  },
  created () {
    this.init()
  },
  mounted () {},
  destroyed () {}
}
</script>
